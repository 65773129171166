import ShowCard from 'Components/AboutUs/ShowCard'
import Description from 'Components/AboutUs/Description'
import VisionAndMision from 'Components/AboutUs/VisionAndMision'

const Index = ({ data, utils }: {data: AboutUs, utils: Utils }) => {
  return (
    <>
      <ShowCard title={ utils.title }/>
      <Description 
        title={ data.title } 
        description={ data.description } 
        companyName={ data.shortName } 
        titleImg={ data.image }
      />
      <VisionAndMision data={data.misionAndVision} />
    </>
  )
}

export default Index