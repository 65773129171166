import { useRef } from 'react';
import NavBar from 'Components/NavBar';
import AboutUs from 'Components/AboutUs';
import Service from 'Components/Service';
import spanish from 'Assets/Texts/spanish.json'
import Footer from 'Components/Footer';
import { Box } from '@mui/material';

const Index = () => {

  const ref = useRef<HTMLDivElement>(null);

  const scrollToService = (serviceName: string) => {
    const service = ref.current.getElementsByClassName(serviceName)[0];
    setTimeout(function () {
      service.scrollIntoView({ behavior: 'smooth', block: "start", });
    }, 1);
  };

  return (
    <>
      <NavBar data={spanish.services} navigate={scrollToService} />
      <AboutUs data={spanish.aboutUs} utils={spanish.utils} />
      <Box 
        marginBottom={10}
        ref={ref}
      >
        {
          spanish.services.map((service, index) =>
            <div className={service.ref} key={service.ref}>
              <Service data={service} utils={spanish.utils} />
            </div>
          )
        }
      </Box>
      <Footer data={spanish.footer} utils={spanish.utils} />
    </>
  )
}

export default Index
