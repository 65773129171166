import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css';
import Router from 'Routes';
// import 'customFont' from './Assets/typography/26301410506.ttf'


// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#015933'
    },
    secondary: {
      main: '#141414'
    },  
  },
  typography: {
    fontFamily: [ 'MyFont', 'MyFont-Bold'].join(','),
    h1: {
      fontFamily: 'MyFont-Bold',
      fontSize: '3rem',
      fontWeight: 900,
    },
    h2: {
      fontFamily: 'MyFont-Bold',
      fontSize: '2.5rem',
      fontWeight: 900,
    },
    h3: {
      fontFamily: 'MyFont-Bold',
      fontSize: '2.2rem',
      fontWeight: 900,
    },
    h4: {
      fontFamily: 'MyFont-Bold',
      fontSize: '1.8rem',
      fontWeight: 900,
    },
    h5: {
      fontFamily: 'MyFont-Bold',
      fontSize: '1.5rem',
      fontWeight: 900,
    },
    body1: {
      fontFamily: 'MyFont',
      fontSize: '1rem',
      fontWeight: 400,
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </React.StrictMode>
);
