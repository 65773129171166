import { Box, Container, Typography } from '@mui/material';

const DataBox = ({ title, text, image }: { title: string, text: string, image: string }) => {
  return (
    <Box
      sx={{ backgroundColor: 'seconday.main' }}
      width={{ sm: '50%' }}
      border={{ xs: 0, sm: 1 }}
      borderBottom='1px solid !important'
      boxSizing='border-box'
      display='flex'
      justifyContent='center'
      alignItems='flex-start'
      flexDirection='column'
      padding={4}
      borderColor='#535353 !important'
    >
      <Box
        paddingBottom={3}
        sx={{ width: '35px', height: '35px' }}
      >
        <img src={image} alt={title + "logo"} style={{ width: '100%', height: '100%' }} />
      </Box>
      <Typography
        color="white"
        variant='h4'
        paddingBottom={3}
      >
        {title}
      </Typography>
      <Typography
        color="white"
        variant='body1'
        paddingBottom={3}
        textAlign='justify'
      >
        {text}
      </Typography>
    </Box>
  )
}


const Index = ({ data }: { data: MisionAndVision }) => {
  const { mision, vision } = data;
  return (
    <Box sx={{ backgroundColor: 'secondary.main' }} >
      <Container
        maxWidth="xl"
        sx={{ paddingX: { xs: 3, md: 6 }, paddingY: 0 }}
      >
        <Box
          display='flex'
          flexDirection='column'
          paddingY={0}
        >
          <Typography
            padding={3}
            color='white'
            variant='h5'
            border={{ xs: 0, sm: 1 }}
            borderBottom={{ xs: 1, sm: 0 }}
            borderColor='#535353 !important'
          >
            {data.title}
          </Typography>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', sm: 'row' }}
            sx={{ width: '100%' }}
          >
            <DataBox title={mision.title} image={mision.image} text={mision.text} />
            <DataBox title={vision.title} image={vision.image} text={vision.text} />
          </Box>
          <Box
            display={{ xs: 'none', sm: 'block' }}
            padding={3}
            borderTop={0}
            borderBottom={0}
            borderLeft={1}
            borderRight={1}
            borderColor='#535353 !important'
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Index