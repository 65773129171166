import { Box, SxProps, Theme, Typography } from "@mui/material"
import Button from 'Components/ContactUsButton'

type Props = {
  title: string,
  subTitle?: string,
  contactButton: boolean,
  fontColor: string,
  sx?: SxProps<Theme>
}

const index = ({ title, subTitle, contactButton, fontColor, sx }: Props) => {
  return (
    <Box
      display='flex'
      padding={4}
      sx={sx}
      flexDirection='column'
      alignItems='center'
    >
      <Typography
        color={fontColor}
        variant='h2'
        textAlign='center'
      >
        {title}
      </Typography>
      {
        (subTitle) &&
        <Typography
          color={fontColor}
          variant='h5'
          textAlign='center'
          fontFamily='MyFont'
        >
          {subTitle}
        </Typography>
      }
      {
        contactButton && <Button 
          text="CONTACTANOS" 
          filled={false} 
          color='white'
          sx={{ 
            border: '1px solid white',
            marginTop: '1rem',
            width: 'fit-content',
          }} 
        />
      }
    </Box>
  )
}

export default index