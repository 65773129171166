import { Box, Container, Grid, SxProps, Theme, Typography } from '@mui/material'
import Button from 'Components/ContactUsButton'
import Title from 'Components/Title'

const Index = ({ title, companyName, description, titleImg }: { title: string, companyName: string, description: string, titleImg: string }) => {
  return (
    <Box
      display='flex'
      flexDirection={'column'}
      justifyItems={'center'}
      marginBottom={10}
    >
      <Container 
        maxWidth="xl" 
        sx={{ paddingX: { xs: 0, md: 6 } }}
      >
        <Title
          title={title}
          fontColor={'primary.main'}
          contactButton={false}
          sx={{ alignItems: 'start', paddingX: 0, marginLeft: { xs: 3, md: 0 } }}
        />
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <PhoneImages title={companyName} imageSrc={titleImg} sx={{ display: { xs: 'flex', md: 'none' } }} />
          <DesktopImages title={companyName} imageSrc={titleImg} sx={{ display: { xs: 'none', md: 'flex' } }} />
          <Container 
            sx={{ 
              maxWidth: '100%',
              paddingX: {xs: 5, md: 5 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid
              display='grid'
              gridTemplateRows={{ xs: '1fr', md: '1fr 1fr' }}
              gap={{ xs: 0, md: 2 }}
              gridAutoFlow={{ xs: 'row', md: 'column' }}
            >
            {
              description.split('\n').map((paragraph, index) =>
                <Typography 
                  key={index + "text"} 
                  variant='body1' 
                  textAlign='justify' 
                  marginBottom={{ xs: 3, md: 0 }}
                >
                  {paragraph}
                </Typography>
              )
            }
            </Grid>
            <Button 
              text='CONTACTANOS' 
              filled={true} 
              color='primary'
              sx={{ alignSelf: { xs: 'flex-start', md: 'flex-end' } }} 
            />
          </Container>
        </Box>
      </Container>
    </Box>
  )
}

const PhoneImages = ({ imageSrc, title, sx }: { imageSrc: string, title: string, sx?: SxProps<Theme> }) => {
  return (
    <Box
      display='flex'
      justifyContent={'center'}
      alignItems={'center'}
      width='100%'
      height='170px'
      sx={sx}
      marginBottom={5}
    >
      <Box
        width='50%'
        height='100%'
      >
        <img src={imageSrc} alt="Mujer con computadora" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
      <Box
        sx={{ backgroundColor: 'primary.main' }}
        width='50%'
        height='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          color="white"
          variant='h5'
          borderBottom={1}
          width='75%'
        >
          {title}
        </Typography>
      </Box>
    </Box>
  )
}


const DesktopImages = ({ imageSrc, title, sx }: { imageSrc: string, title: string, sx?: SxProps<Theme> }) => {
  return (
    <Box
      position='relative'
      overflow='hidden'
      width='50%'
      sx={sx}
    >
      <Box>
        <img src={imageSrc} alt="Mujer con computadora" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
      <Box
        sx={{ backgroundColor: 'primary.main' }}
        width={{ md: '80%', lg: '60%', xl: '50%' }}
        height='75%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        right='0'
        top='0'
        bottom='0'
        margin='auto'
        position='absolute'
        zIndex={1}
      >
        <Typography
          color="white"
          variant='h5'
          borderBottom={1}
          width='60%'
        >
          {title}
        </Typography>
      </Box>
    </Box>
  )
}


export default Index