import { Box, Typography } from '@mui/material'
import React from 'react'

const Index = ({ data, utils, color }: { data: Benefit, utils: Utils, color: string }) => {
  return (
    <Box
      // width={{ xs: '100%', md: '50%' }}
      display='flex'
      flexWrap='wrap'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      padding={4}
      maxWidth='350px'
    >
      <Box
        width='75px'
        marginBottom={4}
      >
        <img src={data.image} alt={"Benefit" + data.title} style={{ height: '100%', width: '100%' }} />
      </Box>
      <Box>
        <Typography variant='h6' color={color}>
          {data.title}
        </Typography>
        <Typography variant='body1' align='justify' >
          {data.description}
        </Typography>
      </Box>
    </Box>
  )
}

export default Index