import Index from "Pages/Home";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  }
]);

const Router = () => <RouterProvider router={router} />;

export default Router;