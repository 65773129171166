import { Box, Container, Typography } from "@mui/material"
import Icon from '@mui/material/Icon';


const index = ({ data, utils }: { data: Footer, utils: Utils }) => {
  return (
    <Box id='footer'>
      <MobileLayout data={data} utils={utils} />
      <DesktopLayout data={data} utils={utils} />
    </Box>
  )
}


const DesktopLayout = ({ data, utils }: { data: Footer, utils: Utils }) => {
  return (
    <Box
      padding={12}
      sx={{ backgroundColor: 'secondary.main', display: { xs: 'none', sm: 'inherit' } }}
    >
      <Container
        maxWidth="xl"
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
      >
        <Box width={{ sm: '50%', md: '40%', lg: '30%' }}>
          <img src={utils.logoFull} alt="" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
        >
          <Box width='45%'>
            <CopyRight data={data} />
            <br />
            <SocialMedia socialMedia={data.socialMedia} />
          </Box>
          <Box width='45%'>
            <ContactUs data={data} utils={utils} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const MobileLayout = ({ data, utils }: { data: Footer, utils: Utils }) => {
  return (
    <Box
      sx={{ backgroundColor: 'secondary.main', display: { xs: 'inherit', sm: 'none' } }}
    >
      <Box
        padding={8}
        paddingBottom={2}
      >
        <img src={utils.logoFull} alt="" style={{ width: '100%', height: '100%' }} />
      </Box>
      <Box
        padding={4}
        paddingX={6}
      >
        <ContactUs data={data} utils={utils} />
        <br />
        <SocialMedia socialMedia={data.socialMedia} />
        <CopyRight data={data} />
      </Box>
    </Box>
  )
}


const ContactUs = ({ data, utils }: { data: Footer, utils: Utils }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection='column'
    >
      <Typography
        color='#00D142'
        variant='h5'
      >
        {utils.contact}
      </Typography>
      <br />
      {
        data.contacts.map((contact, index) =>
          <Box key={index + "_footer_contact"} display='flex' marginBottom={2}>
            <Icon sx={{ justifySelf: 'centar', alignSelf: 'center', color: '#00D142' }}>{contact.icon}</Icon>
            <Typography
              color='white'
              key={index}
              marginLeft={2}
            >
              {contact.text}
            </Typography>
          </Box>
        )
      }
    </Box>
  )
}

const SocialMedia = ({ socialMedia }: { socialMedia: SocialMedia[] }) => {
  return (
    <Box display='flex'>
      {
        socialMedia.map((socialMedia, index) =>
          <Box
            key={index + "_footer_socialMedia"}
            display='flex'
            justifyContent='center'
            alignItems='center'
            marginBottom={2}
            marginRight={2}
          >
            <Box
              color={'white'}
              width='30px'
              marginBottom={4}
              onClick={() => window.open(socialMedia.url, '_blank')}
            >
              <img src={socialMedia.icon} alt={"socialMedia_" + socialMedia.name} style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        )
      }
    </Box>
  )
}

const CopyRight = ({ data }: { data: Footer }) => {
  return (
    <>
      <Box>
        <Typography
          marginY={3}
          color='white'
          align='justify'
          variant='body1'
        >
          {data.disclaimer}
        </Typography>
      </Box>
      <Box
      >
        <Typography
          color='white'
          variant='body2'
        >
          {`® ${new Date().getFullYear()}. ${data.copyRight}`}
        </Typography>
      </Box>
    </>
  )
}
export default index