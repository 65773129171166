import GeneralInfo from 'Components/Service/GeneralInfo'
import Benefit from 'Components/Service/Benefit'
import Title from 'Components/Title'
import StartNow from 'Components/Service/StartNow'
import { Box, Container } from '@mui/material'

const Index = ({ data, utils }: { data: Service, utils: Utils }) => {
  return (
    <>
      <GeneralInfo data={data} utils={utils} />
      <Title title={data.benefits.title} fontColor={data.accentColor} sx={{ backgroundColor: 'white' }} contactButton={false} />
      <Container maxWidth='xl'>
        <Box
          display='flex'
          flexWrap='wrap'
          justifyContent='center'
        >
          {
            data.benefits.items.map((benefit, index) =>
              <Benefit key={"benefit_" + index + "_" + data.title} data={benefit} utils={utils} color={data.accentColor} />
            )
          }
        </Box>
      </Container>
      {data.startNow && <StartNow data={data.startNow} />}
    </>
  )
}

export default Index