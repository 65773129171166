import { Box, Container, Typography } from '@mui/material'
// import ContactusButton from 'Components/ContactUsButton'

const Index = ({ title }: { title: string }) => {
  return (
    <Box
      position='relative'
      overflow='hidden'
      maxHeight={{ xs: '300px', sm: '400px', md: '500px' }}
    >
      <Box>
        <img src='Assets/Images/Header/HeaderBg.jpg' alt="Header Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Box>
      <Container
        maxWidth="xl"
        sx={{ 
          paddingX: { xs: 3, md: 6 }, paddingY: { xs: 3, md: 6 },
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
        }}
      >
        <Content title={title} />
      </Container >
    </Box>
  )
}

const Content = ({ title }: { title: string }) => {
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box
        display='flex'
        padding={{ xs: 0, md: 3 }}
        flexGrow={3}
      >
        <Box
          width={{ xs: '40px', sm: '100px' }}
          height={{ xs: '40px', sm: '100px' }}
          paddingTop={{ xs: 'auto', sm: '1%' }}
          alignSelf={{ xs: 'center', sm: 'auto' }}
          sx={{
            marginRight: '1rem',
            aspectRatio: '1/1',
          }}
        >
          <img src='Assets/Images/Icons/Logo.svg' alt="Logo de la empresa" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Typography
          variant='h4'
          fontSize={{ xs: '1.5rem', sm: '2.5rem' ,md: '3.5rem' }}
          // marginBottom={3}
        >
          {title}
        </Typography>
      </Box>
      <Box
        flexGrow={1}
        maxWidth={{ xs: '150px', sm: '250px', md: '400px' }}
        marginLeft={3}
      >
        <img src='Assets/Images/Header/Header01.jpg' alt="Header01" style={{
          width: '100%',
          height: '100%',
          borderRadius: '40px',
          objectFit: 'cover',
        }} />
      </Box>
    </Box>
  )
}


export default Index