import { Button, SxProps, Theme } from '@mui/material';

type Color = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'white' | 'black';


function Index({ text, filled, color, sx }: { text: string, filled: boolean, color: Color, sx?: SxProps<Theme> }) {

  const handleClick = (ev: any) => {
    const element = document.getElementById('footer');
    element?.scrollIntoView({ behavior: 'smooth', block: "start", });
  }

  return (
    <Button
      style={{
        borderRadius: '20px',
        color
      }}
      variant={filled ? 'contained' : 'outlined'}
      sx={sx}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

export default Index