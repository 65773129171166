import { Box } from '@mui/material';
import Title from 'Components/Title';

const Index = ({ data }: { data: StartNow }) => {
  return (
    <Box
    >
      <Box
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          width='100%'
          height='300px'
          display={{ xs: 'none', sm: 'inherit' }}
        >
          <img src={data.image1} alt="Start Now" style={{ width: '50%', height: '100%', objectFit: 'cover' }} />
          <img src={data.image2} alt="Start Now" style={{ width: '50%', height: '100%', objectFit: 'cover' }} />
        </Box>
      </Box>
      <Title 
        title={data.title} 
        fontColor='white' 
        sx={{ 
          backgroundColor: 'secondary.main',
          paddingY: 8,
        }} 
        contactButton={true} 
      />
    </Box>
  )
}

export default Index