import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';


const AppMenu = ({ pages, navigate }: { pages: Service[], navigate: (service: string) => void }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (ev: any, ref: string) => {
    ev.preventDefault();
    setAnchorElNav(null);
    if (ref) navigate(ref);
  };

  return (
    <>
      {/* Mobile */}
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'flex-end' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNav)}
          onClose={(ev: any) => handleCloseNavMenu(ev, null)}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {pages.map((page) => (
            <MenuItem
              key={page.ref}
              onClick={(ev: any) => handleCloseNavMenu(ev, page.ref)}
            >
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* Large Display */}
      <Box
        justifyContent={'flex-end'}

        sx={{
          flexGrow: 1,
          display: {xs: 'none', md: 'flex'},
        }}>
        {pages.map((page, index) => (
          <>
            <Button
              key={page.ref}
              onClick={(ev: any) => handleCloseNavMenu(ev, page.ref)}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page.title}
            </Button>
            {index < pages.length - 1 && <Box alignSelf='center' width={2} height={20} marginX={1}  sx={{ backgroundColor: 'white' }} />}
          </>
        ))}
      </Box>
    </>
  )
}

function ResponsiveAppBar({ data, navigate }: { data: Service[], navigate: (service: string) => void }) {
  return (
    <AppBar position="sticky" color='primary'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <img src='Assets/Images/Logo.png' alt="Logo" style={{ width: '10rem' }} />
          </Box>
          <AppMenu pages={data} navigate={navigate} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}


export default ResponsiveAppBar;