import { Box, Typography } from '@mui/material'
import Button from 'Components/ContactUsButton'
import Title from 'Components/Title';

const Index = ({ data, utils }: { data: Service, utils: Utils }) => {
  const accentColor = data.accentColor;
  const existsImage = data.image != null;

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <Title
        title={data.title}
        subTitle={data.subTitle}
        fontColor={'black'}
        sx={{ bgcolor: 'white', paddingTop: 16, paddingBottom: 12 }}
        contactButton={false}
      />
      <Box
        display='flex'
        width='100%'
        flexWrap='wrap'
      >
        {
          existsImage &&
          <Box
            display={{ xs: 'none', sm: 'block' }}
            width={{ xs: '100%', sm: '50%' }}
          >
            <img src={data.image} alt={data.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
        }
        <Box
          sx={{ backgroundColor: accentColor }}
          width={{ xs: '100%', sm: '50%' }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Box
            padding={{ xs: 5, sm: 8, md: 12, lg: 16 }}
          >
            <Typography
              color="white"
              variant='body1'
              textAlign='justify'
            >
              {data.description}
            </Typography>
            <Button
              text='CONTACTANOS'
              color='white'
              filled={false}
              sx={{ marginTop: 4, border: '1px solid white', alignSelf: 'flex-start' }}
            />
          </Box>
        </Box>
        <Box
          maxWidth='100%'
          flex={1}
          display='flex'
          flexDirection='column'
          sx={{ backgroundColor: 'secondary.main' }}
        >
          <Typography
            color='white'
            variant='h1'
            fontSize={{ xs: '1.5rem', sm: '2rem' }}
            textAlign='center'
            paddingTop={3}
            paddingBottom={3}
          >
            {utils.ourProcess}
          </Typography>
          <Box
            display='flex'
            alignContent='center'
            justifyContent='center'
            height='100%'
            padding={{ xs: 2, sm: 5, md: 8, lg: 10 }}
          >
            <img src={data.processImage} alt={data.title} style={{ width: '100%', objectFit: 'contain' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Index